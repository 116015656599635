export default [
  {
    question: 'Can you ship internationally?',
    answer:
      'Yes, Vaporfly.com offers international shipping, including U.S., Canada and Europe. Please, contact us directly for more information.',
  },
  {
    question: 'Do I need to create an account to place orders from Vaporfly?',
    answer:
      'Yes, to place orders online 24/7, please create an account at Vaporfly.com, or contact our sales manager directly.',
  },
  {
    question: 'Where does Vaporfly ship from?',
    answer:
      'If the product is in stock, the order is shipped from our U.S. warehouse. In other cases, the orders are shipped from the manufacturer’s warehouse in China.',
  },
  {
    question: 'What payment method does Vaporfly accept? ',
    answer:
      'We accept credit card payments, as well as bank transfers. In case of a bank transfer, you will receive an email from Vaporfly.com with payment instructions after placing the order. Make sure to include the correct payment reference in the transfer so we can link your payment to your order.\nFor other payment options, please contact our sales team directly.',
  },
  {
    question: 'What carrier does Vaprfly use to ship?',
    answer: 'We use international couriers, such as FedEx and DHL, USPS  for safe, affordable and fast deliveries.',
  },
  {
    question: 'What should I do if the product I am looking for is out of stock?',
    answer:
      'Please, contact our sales manager directly to clarify the availability of the product or to find a similar product according to your requirements.',
  },
  {
    question: 'How do I request for samples?',
    answer: 'Please, contact our sales manager or place an order online.',
  },
  {
    question: 'Can you return personalized products?',
    answer: 'No, the return policy does not apply for personalized products.',
  },
  {
    question: 'What is your return policy?',
    answer:
      'Our return policy rules comply with <a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=CIV&sectionNum=1791.1">Consumer warranty protection.</a>',
  },
  {
    question: 'What products are eligible for returns?',
    answer:
      'Products received damaged, have missing parts or accessories, defective or different from their description are eligible for return.',
  },
  {
    question: 'How do I know if my order is processed?',
    answer:
      'You will receive email notifications regarding the status of your order. You can also check the status of your order by contacting our customer care team or directly with your VaporFly sales manager.',
  },
  // {
  //   question: 'What materials are your products made from?',
  //   answer: '...',
  // },
  // {
  //   question:
  //     'Do we offer products that can highlight environmentally sustainable, and socially responsible manufacturing practices?',
  //   answer: '...',
  // },
  // {
  //   question:
  //     'Have we aligned with any influential brand partners that are doing game changing socially responsible programs that we can team with?',
  //   answer: '...',
  // },
  // {
  //   question:
  //     'Are we currently involved in creating innovative IP or tech that will set us apart and make us even more relevant five years from now?',
  //   answer: '...',
  // },
  {
    question: "I'm interested in getting a new logo. Can you guys help?",
    answer: 'Yes, sure, we provide custom services, please contact our manager.',
  },
  {
    question: 'Where are your warehouses located?',
    answer: '<strong>Sacramento, California</strong>',
  },
  {
    question: 'Can I place an order and pick it up today?',
    answer: 'If the product is in stock in our warehouse, we make dispatches immediately after receiving payment.',
  },
  {
    question: 'Where can I find the specs/dimensions of an item?',
    answer:
      'All details of a product can be found on the product page if not, please contact your manager to get more information',
  },
  {
    question: 'Can I get a custom product?',
    answer: 'Yes! We specialize in all types of custom branded packaging and hardware.',
  },
  {
    question: 'Can I design my own product?',
    answer: 'Yes! You can try <a href="/customization">here</a>',
  },
  {
    question: "I would like to purchase a certain product, but I can't find it on your website. What should I do?",
    answer:
      'If the product is found on another website, or from another seller, please let us know and we will do our best to accommodate you.',
  },
  {
    question: 'What does "child resistant" mean?',
    answer:
      'Child resistant packaging is designed to prevent children under the age of 5 from accessing household products, pharmaceuticals, and other products from unintended intake. The packaging must also be easy enough for adults between the ages of 50-70 to open. All child resistant products have been third-party tested and certified to pass Title 16 CFR 1700.',
  },
];
