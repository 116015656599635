<template>
  <div class="container p-tb-2">
    <div class="row">
      <div class="col-lg-12 col-xs-12 page-faq">
        <h1 class="text-center">F.A.Q.</h1>
        <ul class="faq-list" ref="list">
          <template v-for="(item, i) in faqs">
            <li :key="i" class="faq-item" :class="{ active: active === i }" @click="e => setActive(e, i)">
              <h2 class="question">{{ i + 1 }}. {{ item.question }}</h2>
              <div class="answer">
                <template v-for="(item, i) in item.answer.split('\n')">
                  <p v-html="item" :key="i" />
                </template>
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import MetaMixin from 'Lib/mixins/MetaMixin';
import faq from 'Lib/contents/faq';

export default {
  name: 'FAQ',
  mixins: [MetaMixin],
  computed: {
    faqs() {
      return faq;
    },
  },
  methods: {
    setActive(e, i) {
      const PROP = '--a-height';
      const target = e.target.closest('li');
      target
        .closest('ul')
        .querySelectorAll('li.active')
        .forEach(el => {
          el.style.removeProperty(PROP);
        });
      const { height } = target.querySelector('.answer').getBoundingClientRect();
      target.style.setProperty(PROP, `${height}px`);
      this.active = i;
    },
  },
  data() {
    return {
      active: 0,
    };
  },
  beforeMount() {
    this.$store.dispatch('setBreadcrumbs', [{ to: this.$route.path, title: 'F.A.Q.' }]);
  },
  mounted() {
    if (this.$refs.list) {
      const items = this.$refs.list.querySelectorAll('li');
      for (const li of items) {
        const q = li.querySelector('.question');
        li.style.setProperty('--q-height', `${q.getBoundingClientRect().height}px`);
      }
    }
  },
};
</script>

<style type="text/css" lang="scss">
.page-faq {
  ul.faq-list {
    list-style: none;
    margin-block: 0;
    margin-inline: 0;
    padding-inline: 0;

    li {
      --q-height: 25px;
      --a-height: 0px;
      position: relative;
      display: flex;
      flex-direction: column;
      border: 2px solid var(--c-primary);
      border-radius: 5px;
      padding: 1rem 2rem;
      cursor: pointer;
      background-color: #f2f1ef;
      transition: all ease-in-out 0.25s;
      overflow: hidden;
      height: calc(var(--q-height) + var(--a-height));

      .question {
        margin: 0;
      }

      .answer {
        margin: 0;

        p {
          line-height: 1.5rem;
          text-indent: 1rem;
          font-size: larger;
          display: block;
        }

        a {
          text-decoration: underline;
          color: var(--c-primary);
          font-weight: 500;
        }
      }

      &:not(:last-child) {
        margin-bottom: 2rem;
      }

      &.active .answer {
        display: block;
      }
    }
  }
}
</style>
